import IncomeForm from "./IncomeForm";
import PeopleList from "./List";
import PeopleNew from "./New";
import PeopleShow from "./Show";

export default [
  {
    path: "/people",
    element: <PeopleList />,
    children: [
      {
        path: "new",
        element: <PeopleNew />,
      },
    ],
  },
  {
    path: "/people/:personId",
    element: <PeopleShow />,
    children: [
      {
        path: "incomes/new",
        element: <IncomeForm />,
      },
      {
        path: "incomes/:incomeId",
        element: <IncomeForm />,
      },
    ],
  },
];
