import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  added as ownershipAdded,
  removed as ownershipRemoved,
} from "./companyOwnerships";

import { CountryIso } from "../Countries";

export interface Company {
  id: string;
  name: string;
  countryCode: CountryIso;

  ownershipIds: string[];
}

export type CompaniesState = Record<string, Company>;

const initialState: CompaniesState = {};

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    added: (state, action: PayloadAction<Company>) => {
      state[action.payload.id] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ownershipAdded, (state, action) => {
      const company = state[action.payload.companyId];
      company.ownershipIds.push(action.payload.id);
      company.ownershipIds = company.ownershipIds.filter((id, index, a) => {
        return a.indexOf(id) === index;
      });
    });

    builder.addCase(ownershipRemoved, (state, action) => {
      const company = state[action.payload.companyId];
      company.ownershipIds = company.ownershipIds.filter(
        (id) => id !== action.payload.id
      );
    });
  },
});

export const { added } = companiesSlice.actions;
export default companiesSlice.reducer;
