import IGrowthStrategy from "./IGrowthStrategy";

export default class InflationGrowthStrategy implements IGrowthStrategy {
  constructor(
    public readonly add: number = 0,
    public readonly multiply: number = 1
  ) {}

  change(amount: number): number {
    const inflation = 0.1; // TODO: getWorld().currentInflation;
    return amount * (1 + inflation * this.multiply + this.add);
  }
}
