import React from "react";

const Input = React.forwardRef(
  (
    props: React.ComponentPropsWithoutRef<"input">,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { type, className, ...rest } = props;

    return (
      <input
        type={type ?? "text"}
        ref={ref}
        className={`
          block
          w-full
          rounded-md shadow-sm
          py-2
          sm:text-sm
          border-gray-300
          dark:bg-slate-700
          dark:text-white
          ${className ?? ""}
        `}
        {...rest}
      />
    );
  }
);

export default Input;
