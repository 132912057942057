import { TFunction } from "i18next";
import { matchSorter } from "match-sorter";
import { useTranslation } from "react-i18next";
import { OptionProps, SingleValueProps } from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";

import SearchableSelect, { SelectProps } from "./SearchableSelect";

import {
  CountryFlag,
  CountryIso,
  EUROPEAN_COUNTRIES,
  OTHER_COUNTRIES,
} from "../../Countries";

interface OptionType {
  label: string;
  value?: CountryIso;
  flag?: string;
}

function RenderHeader(props: SingleValueProps<OptionType>) {
  return (
    <>
      {props.data.flag} {props.data.label}
    </>
  );
}

function RenderOption(props: OptionProps<OptionType>) {
  return (
    <>
      {props.data.flag} {props.label}
    </>
  );
}

function isoToOption(iso: CountryIso, t: TFunction<"countries">): OptionType {
  return {
    value: iso,
    label: t(iso),
    flag: CountryFlag(iso),
  };
}

function filter(option: FilterOptionOption<OptionType>, inputValue: string) {
  return (
    matchSorter([option.label], inputValue).length !== 0 ||
    option.value === inputValue.toUpperCase()
  );
}

function sortOptions(options: OptionType[]) {
  return options.sort((a, b) => a.label.localeCompare(b.label));
}

interface CountrySelectProps
  extends Omit<
    SelectProps<OptionType>,
    "options" | "onChange" | "renderHeader" | "renderOption"
  > {
  iso?: CountryIso;
  onChange: (iso: string) => void;
}

export default function CountrySelect(props: CountrySelectProps) {
  const { iso, onChange, ...rest } = props;

  const { t } = useTranslation();
  const countryNames = useTranslation("countries").t;

  const eu = EUROPEAN_COUNTRIES.map((iso) => isoToOption(iso, countryNames));
  const other = OTHER_COUNTRIES.map((iso) => isoToOption(iso, countryNames));

  const options = [
    { label: t("europeanUnion"), options: sortOptions(eu) },
    { label: t("otherCountries"), options: sortOptions(other) },
  ];

  const value =
    typeof iso !== "undefined" ? isoToOption(iso, countryNames) : null;

  return (
    <SearchableSelect
      value={value}
      options={options}
      renderHeader={RenderHeader}
      renderOption={RenderOption}
      filterOption={filter}
      onChange={(option) => onChange(option?.value ?? "")}
      {...rest}
    />
  );
}
