import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PropertyOwnership {
  id: string;
  propertyId: string;
  personId: string | null;
  ownershipQuotas: number;
  ownershipDivisor: number;
}

export type PropertyOwnershipsState = Record<string, PropertyOwnership>;

const initialState: PropertyOwnershipsState = {};

export const propertyOwnershipsSlice = createSlice({
  name: "companyOwnerships",
  initialState,
  reducers: {
    added: (state, action: PayloadAction<PropertyOwnership>) => {
      state[action.payload.id] = action.payload;
    },

    updated: (state, action: PayloadAction<PropertyOwnership>) => {
      state[action.payload.id] = action.payload;
    },

    removed: (state, action: PayloadAction<PropertyOwnership>) => {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete state[action.payload.id];
    },
  },
});

export const { added, updated, removed } = propertyOwnershipsSlice.actions;
export default propertyOwnershipsSlice.reducer;
