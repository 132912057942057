import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { matchSorter } from "match-sorter";
import { useCallback } from "react";
import { FieldError, RefCallBack } from "react-hook-form";
import Select, {
  components,
  DropdownIndicatorProps,
  OptionProps,
  SingleValue,
  SingleValueProps,
} from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import Label from "./Label";

export interface SelectProps<T = unknown> {
  id: string;
  name: string;
  label: React.ReactNode;
  description?: string;
  placeholder?: string;
  options: T[];
  value?: T | null;
  error: FieldError | undefined;
  inputRef: RefCallBack;
  renderHeader: (props: SingleValueProps<T>) => JSX.Element;
  renderOption: (props: OptionProps<T>) => JSX.Element;
  onChange?: (value: T | null) => void;
  filterOption?: (option: FilterOptionOption<T>, inputValue: string) => boolean;
}

function filter<T>(option: FilterOptionOption<T>, inputValue: string) {
  return matchSorter([option.label], inputValue).length !== 0;
}

function renderHeader<T>(inner: (props: SingleValueProps<T>) => JSX.Element) {
  return (props: SingleValueProps<T>) => {
    console.log(props);
    return (
      <components.SingleValue {...props}>{inner(props)}</components.SingleValue>
    );
  };
}

function renderOption<T>(inner: (props: OptionProps<T>) => JSX.Element) {
  return (props: OptionProps<T>) => {
    return (
      <div
        ref={props.innerRef}
        {...props.innerProps}
        className={`${
          props.isFocused ? "text-white bg-indigo-600" : "text-gray-900"
        }
          sm:text-sm
          relative
          cursor-default
          select-none
          py-2 pl-3 pr-9
          whitespace-nowrap`}
      >
        {inner(props)}
      </div>
    );
  };
}

function DropdownIndicator<T>(isInvalid: boolean) {
  return (props: DropdownIndicatorProps<T>) => {
    if (isInvalid) {
      return (
        <components.DropdownIndicator
          {...props}
          className="flex items-center !px-3"
        >
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </components.DropdownIndicator>
      );
    } else {
      return (
        <components.DropdownIndicator
          {...props}
          className="flex items-center !px-3"
        />
      );
    }
  };
}

export default function SearchableSelect<T>(props: SelectProps<T>) {
  const onChange = useCallback(
    (value: SingleValue<T> | readonly T[]) => {
      props.onChange?.(value as T | null);
    },
    [props.onChange]
  );

  const {
    id,
    name,
    label,
    description,
    placeholder,
    options,
    value,
    error,
    filterOption,
    inputRef,
  } = props;

  const isInvalid = error !== undefined;

  return (
    <div className={`${isInvalid ? "react-select--is-invalid" : ""}`}>
      <Label>{label}</Label>
      <Select
        classNamePrefix="react-select"
        options={options}
        onChange={onChange}
        id={id}
        ref={inputRef}
        name={name}
        placeholder={placeholder}
        value={value}
        filterOption={filterOption ?? filter}
        aria-invalid={isInvalid}
        components={{
          DropdownIndicator: DropdownIndicator(isInvalid),
          Option: renderOption(props.renderOption),
          SingleValue: renderHeader(props.renderHeader),
        }}
      />
      {isInvalid ? (
        <p className="mt-1 text-sm text-red-600" id={`${id}-error`}>
          {error.message}
        </p>
      ) : (
        description && (
          <p className="mt-1 text-sm text-gray-500" id={`${id}-description`}>
            {description}
          </p>
        )
      )}
    </div>
  );
}
