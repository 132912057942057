import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Person {
  id: string;
  name: string;
  birthMonth: number;
  birthYear: number;
}

export type PeopleState = Record<string, Person>;

const initialState: PeopleState = {};

export const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {
    added: (state, action: PayloadAction<Person>) => {
      state[action.payload.id] = action.payload;
    },
  },
});

export const { added } = peopleSlice.actions;
export default peopleSlice.reducer;
