import { DevTool } from "@hookform/devtools";
import { useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import GrowthSubform from "./GrowthSubform";

import Button from "../../components/atoms/Button";
import Modal, { ModalContext } from "../../components/atoms/Modal";
import InputField from "../../components/fields/Field";
import Select from "../../components/fields/Select";
import { CurrencyField } from "../../components/useForm";

import GenerateId from "../../IDGen";
import { RootState } from "../../store";
import { added, Income } from "../../store/incomes";

export default function IncomeForm() {
  const { personId, incomeId } = useParams();
  if (!personId) {
    return null;
  }

  const income = useSelector((state: RootState) => {
    if (!incomeId) {
      return null;
    }
    return state.incomes[incomeId];
  });

  const existingIncomesCount = useSelector(
    (state: RootState) => Object.keys(state.incomes).length
  );

  const methods = useForm<Income>({
    defaultValues: income ?? {
      name: `Salary #${existingIncomesCount + 1}`,
      frequency: "yearly",
      initialAmount: {
        amount: 0,
        currency: "EUR",
      },
      growthStrategy: {
        type: "null",
        // multiply: 1,
        // add: 0,
      },
    },
  });

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
  } = methods;

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (data: Income, atEnd: () => void) => {
      data.id = incomeId ?? GenerateId("incm");
      data.personId = personId;
      data.type = "salary";

      console.log(data);

      dispatch(added(data));

      atEnd();
    },
    [personId, incomeId]
  );

  const { t } = useTranslation("incomes");
  const common = useTranslation("common").t;

  return (
    <Modal className="sm:max-w-4xl">
      <ModalContext.Consumer>
        {({ close }) => (
          <>
            <Modal.Header title={t("form.title")} close={close} />
            <DevTool control={control} />

            <form onSubmit={handleSubmit((data) => onSubmit(data, close))}>
              <Modal.Body>
                <FormProvider {...methods}>
                  <div className="mb-3">
                    <InputField
                      id="income-name"
                      label={t("form.name")}
                      description={t("form.nameDescription")}
                      placeholder={t("form.namePlaceholder")}
                      error={errors.name}
                      {...register("name", {
                        required: t("form.nameRequired"),
                      })}
                    />
                  </div>
                  <div className="mb-3 gap-3 flex">
                    <CurrencyField
                      id="income-amount"
                      currency={income?.initialAmount.currency ?? "EUR"}
                      className="flex-1"
                      label={t("form.amount")}
                      description={t("form.amountDescription")}
                      error={errors.initialAmount?.amount}
                      name="initialAmount.amount"
                      rules={{
                        required: t("form.amountRequired"),
                        min: {
                          value: 0.001,
                          message: t("form.amountMin"),
                        },
                      }}
                    />

                    <Controller
                      render={({ field }) => (
                        <Select
                          id="income-frequency"
                          className="flex-1"
                          label={t("form.frequency")}
                          description={t("form.frequencyDescription")}
                          error={errors.frequency}
                          options={[
                            { id: "daily", name: common("frequencies.daily") },
                            {
                              id: "weekly",
                              name: common("frequencies.weekly"),
                            },
                            {
                              id: "monthly",
                              name: common("frequencies.monthly"),
                            },
                            {
                              id: "yearly",
                              name: common("frequencies.yearly"),
                            },
                          ]}
                          {...field}
                        />
                      )}
                      control={control}
                      name="frequency"
                    />
                  </div>

                  <div className="mb-3">
                    <GrowthSubform />
                  </div>
                </FormProvider>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="primary" type="submit">
                  {common("confirm")}
                </Button>

                <Button variant="white" onClick={close}>
                  {common("back")}
                </Button>
              </Modal.Footer>
            </form>
          </>
        )}
      </ModalContext.Consumer>
    </Modal>
  );
}
