import {
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import { CurrencyIso } from "../../Currencies";
import CurrencyField, { CurrencyFieldProps } from "../fields/CurrencyField";

export interface FormCurrencyFieldProps<T extends FieldValues>
  extends CurrencyFieldProps {
  name: Path<T>;
  currency: CurrencyIso;
  rules?: RegisterOptions<T, Path<T>>;
}

export function FormCurrencyField<T extends FieldValues>(
  props: FormCurrencyFieldProps<T>
) {
  const { name, rules, value, ...rest } = props;
  const { control } = useFormContext<T>();

  !value; // value is not used, we're using the controller's value instead

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <CurrencyField
          name={field.name}
          value={field.value}
          onChange={(e) => {
            const val = parseFloat(e.target.value);
            if (isNaN(val)) {
              console.log("SETTING TO und");
              field.onChange(undefined);
            } else {
              field.onChange(val);
            }
          }}
          onBlur={(e) => {
            const val = parseFloat(e.target.value);
            if (isNaN(val)) {
              console.log("SETTING TO und");
              field.onChange(undefined);
            } else {
              console.log("SETTING TO for blur", val);

              field.onChange(val);
            }
          }}
          error={error}
          ref={field.ref}
          {...rest}
        />
      )}
    />
  );
}

export default FormCurrencyField;
