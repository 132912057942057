import { EyeIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { CountryFlag } from "../../Countries";
import { RootState } from "../../store";

export default function CompaniesList() {
  const companies = useSelector((state: RootState) => state.companies);

  return (
    <div>
      <h1 className="text-5xl mb-12">Companies page</h1>
      <p className="mb-3">
        If you own a company or are part of a partnership, you can add it here.
        This will help you model complex scenarios like a dividends and business
        taxes.
        <em>
          If you are trying to model the value of shares in a company, you can
          add it as an asset instead.
        </em>
      </p>

      <Link to="/companies/new">New Company</Link>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
      >
        {Object.values(companies).map((company) => (
          <li
            key={company.id}
            className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
          >
            <Link to={`/companies/${company.id}`}>
              <div className="flex w-full items-center justify-between space-x-6 p-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="truncate text-sm font-medium text-gray-900">
                      {CountryFlag(company.countryCode)} {company.name}
                    </h3>
                  </div>
                  <p className="mt-1 truncate text-sm text-gray-500">
                    {company.countryCode}
                  </p>
                </div>
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="flex w-0 flex-1">
                    <div className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500">
                      <EyeIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">Open</span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>

      <Outlet />
    </div>
  );
}
