import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Button from "../../components/atoms/Button";
import Modal, { ModalContext } from "../../components/atoms/Modal";
import InputField from "../../components/fields/Field";
import MonthSelect from "../../components/fields/MonthSelect";

import GenerateId from "../../IDGen";
import { added } from "../../store/people";

interface FormValues {
  name: string;
  birthMonth: number;
  birthYear: number;
}

export default function PeopleNew() {
  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      birthMonth: new Date().getMonth(),
      birthYear: new Date().getFullYear() - 30,
    },
  });

  const dispatch = useDispatch();

  const onSubmit = useCallback((data: FormValues, atEnd: () => void) => {
    const { name, birthMonth, birthYear } = data;

    dispatch(
      added({
        id: GenerateId("per"),
        name,
        birthMonth,
        birthYear,
      })
    );

    atEnd();
  }, []);

  const { t } = useTranslation("people");
  const common = useTranslation("common").t;

  return (
    <Modal className="sm:max-w-lg">
      <ModalContext.Consumer>
        {({ close }) => (
          <>
            <Modal.Header title={t("newForm.title")} close={close} />

            <form onSubmit={handleSubmit((data) => onSubmit(data, close))}>
              <Modal.Body>
                <div className="mb-4">
                  <InputField
                    id="person-name"
                    label={t("newForm.name")}
                    error={errors.name}
                    placeholder={t("newForm.namePlaceholder")}
                    description={t("newForm.nameDescription")}
                    autoComplete="off"
                    {...register("name", {
                      required: t("newForm.nameError"),
                    })}
                  />
                </div>
                <div className="mb-4 flex">
                  <div className="w-1/2 mr-2">
                    <Controller
                      render={({ field }) => (
                        <MonthSelect
                          id="person-birth-month"
                          label={t("newForm.birthMonth")}
                          error={errors.birthMonth}
                          {...field}
                        />
                      )}
                      rules={{ required: true }}
                      control={control}
                      name="birthMonth"
                    />
                  </div>
                  <div className="w-1/2 ml-2">
                    <InputField
                      id="person-birth-year"
                      min={1900}
                      max={new Date().getFullYear()}
                      label={t("newForm.birthYear")}
                      error={errors.birthYear}
                      autoComplete="off"
                      {...register("birthYear", { required: true })}
                    />
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="primary" type="submit">
                  {common("confirm")}
                </Button>

                <Button variant="white" onClick={close}>
                  {common("back")}
                </Button>
              </Modal.Footer>
            </form>
          </>
        )}
      </ModalContext.Consumer>
    </Modal>
  );
}
