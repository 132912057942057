import { Link, useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <div className="py-6 px-8 shadow-lg rounded-lg bg-white">
        <h1 className="text-3xl mb-3 font-medium">
          The page you were looking for does not exist
        </h1>
        <p>
          You probably clicked on an old or invalid link that has not been
          fixed.
        </p>

        <div className="flex gap-3 justify-end mt-8">
          <button
            onClick={() => navigate(-1)}
            className="
              bg-transparent text-slate-500
              hover:bg-slate-500 hover:text-white
              transition-all
              font-semibold
              py-2 px-5
              border border-slate-500 hover:border-transparent
              rounded"
          >
            Go back
          </button>
          <Link
            to="/"
            className="
              bg-blue-500 hover:bg-blue-600
              text-white
              transition-all
              font-semibold
              py-2 px-5
              rounded"
          >
            Go to your dashboard
          </Link>
        </div>
      </div>
    </div>
  );
}
