import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import React, { ReactNode } from "react";
import { FieldError } from "react-hook-form";
import Input from "./Input";
import Label from "./Label";

export interface InputProps extends React.ComponentPropsWithoutRef<"input"> {
  label: ReactNode;
  id: string;
  name?: string;
  type?: "text" | "email" | "password" | "hidden";
  description?: string;
  error?: FieldError;
}

const Field = React.forwardRef(
  (props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { label, id, description, error, className, ...rest } = props;

    const classes = error
      ? "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500"
      : "focus:border-indigo-500 focus:ring-indigo-500 dark:focus:border-green-300 dark:focus:ring-green-300";

    return (
      <div className={className}>
        <Label htmlFor={id}>{label}</Label>
        <div className="relative mt-1">
          <Input
            ref={ref}
            aria-invalid={error ? "true" : "false"}
            aria-describedby={
              error
                ? `${id}-error`
                : description
                ? `${id}-description`
                : undefined
            }
            className={classes}
            {...rest}
          />
          {error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {error ? (
          <p className="mt-1 text-sm text-red-600" id={`${id}-error`}>
            {error.message}
          </p>
        ) : (
          description && (
            <p
              className="mt-1 text-sm text-gray-500 dark:text-gray-400"
              id={`${id}-description`}
            >
              {description}
            </p>
          )
        )}
      </div>
    );
  }
);

export default Field;
