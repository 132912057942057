import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

/* eslint-disable import/no-named-as-default-member */
i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    load: "languageOnly",
    ns: ["countries", "common", "companies", "currencies", "incomes", "people"],
    defaultNS: "common",
    fallbackLng: "en",
    lng: "it",
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
  .catch(console.error);
/* eslint-enable */

export default i18next;

export const getDecimalSeparator = (locale: string = navigator.language) => {
  const n = 1.1;
  return Intl.NumberFormat(locale).format(n).substring(1, 2);
};

export const parseLocalizedFloat = (
  value: string,
  locale: string = navigator.language
) => {
  const decimalSeparator = getDecimalSeparator(locale);
  const regex = new RegExp(`[^0-9${decimalSeparator}]`, "g");
  return parseFloat(value.replace(regex, "").replace(decimalSeparator, "."));
};
