import { CountryIso } from "./Countries";
import { Money } from "./store/types";

export type CurrencyIso =
  | "AED"
  | "AFN"
  | "ALL"
  | "AMD"
  | "ANG"
  | "AOA"
  | "ARS"
  | "AUD"
  | "AWG"
  | "AZN"
  | "BAM"
  | "BBD"
  | "BDT"
  | "BGN"
  | "BHD"
  | "BIF"
  | "BMD"
  | "BND"
  | "BOB"
  | "BRL"
  | "BSD"
  | "BTN"
  | "BWP"
  | "BYN"
  | "BZD"
  | "CAD"
  | "CDF"
  | "CHF"
  | "CLP"
  | "CNY"
  | "COP"
  | "CRC"
  | "CUP"
  | "CVE"
  | "CZK"
  | "DJF"
  | "DKK"
  | "DOP"
  | "DZD"
  | "EGP"
  | "ERN"
  | "ETB"
  | "EUR"
  | "FJD"
  | "FKP"
  | "GBP"
  | "GEL"
  | "GGP"
  | "GHS"
  | "GIP"
  | "GMD"
  | "GNF"
  | "GTQ"
  | "GYD"
  | "HKD"
  | "HNL"
  | "HTG"
  | "HUF"
  | "IDR"
  | "ILS"
  | "IMP"
  | "INR"
  | "IQD"
  | "IRR"
  | "ISK"
  | "JEP"
  | "JMD"
  | "JOD"
  | "JPY"
  | "KES"
  | "KGS"
  | "KHR"
  | "KMF"
  | "KPW"
  | "KRW"
  | "KWD"
  | "KYD"
  | "KZT"
  | "LAK"
  | "LBP"
  | "LKR"
  | "LRD"
  | "LSL"
  | "LYD"
  | "MAD"
  | "MDL"
  | "MGA"
  | "MKD"
  | "MMK"
  | "MNT"
  | "MOP"
  | "MRU"
  | "MUR"
  | "MVR"
  | "MWK"
  | "MXN"
  | "MYR"
  | "MZN"
  | "NAD"
  | "NGN"
  | "NIO"
  | "NOK"
  | "NPR"
  | "NZD"
  | "OMR"
  | "PAB"
  | "PEN"
  | "PGK"
  | "PHP"
  | "PKR"
  | "PLN"
  | "PYG"
  | "QAR"
  | "RON"
  | "RSD"
  | "RUB"
  | "RWF"
  | "SAR"
  | "SBD"
  | "SCR"
  | "SDG"
  | "SEK"
  | "SGD"
  | "SHP"
  | "SLE"
  | "SOS"
  | "SRD"
  | "SSP"
  | "STN"
  | "SYP"
  | "SZL"
  | "THB"
  | "TJS"
  | "TMT"
  | "TND"
  | "TOP"
  | "TRY"
  | "TTD"
  | "TVD"
  | "TWD"
  | "TZS"
  | "UAH"
  | "UGX"
  | "USD"
  | "UYU"
  | "UZS"
  | "VEF"
  | "VND"
  | "VUV"
  | "WST"
  | "XAF"
  | "XCD"
  | "XOF"
  | "XPF"
  | "YER"
  | "ZAR"
  | "ZMW"
  | "ZWL";

interface Currency {
  symbol: string;
  flag: CountryIso | "EU";
  decimalPlaces?: number;
}

const currencies: Record<CurrencyIso, Currency> = {
  AED: {
    symbol: "د.إ",
    flag: "AE",
  },
  AFN: {
    symbol: "؋",
    flag: "AF",
  },
  ALL: {
    symbol: "L",
    flag: "AL",
  },
  AMD: {
    symbol: "֏",
    flag: "AM",
  },
  ANG: {
    symbol: "ƒ",
    flag: "CW",
  },
  AOA: {
    symbol: "Kz",
    flag: "AO",
  },
  ARS: {
    symbol: "$",
    flag: "AR",
  },
  AUD: {
    symbol: "$",
    flag: "AU",
  },
  AWG: {
    symbol: "ƒ",
    flag: "AW",
  },
  AZN: {
    symbol: "₼",
    flag: "AZ",
  },
  BAM: {
    symbol: "KM",
    flag: "BA",
  },
  BBD: {
    symbol: "$",
    flag: "BB",
  },
  BDT: {
    symbol: "৳",
    flag: "BD",
  },
  BGN: {
    symbol: "лв",
    flag: "BG",
  },
  BHD: {
    symbol: ".د.ب",
    flag: "BH",
    decimalPlaces: 3,
  },
  BIF: {
    symbol: "FBu",
    flag: "BI",
  },
  BMD: {
    symbol: "$",
    flag: "BM",
  },
  BND: {
    symbol: "$",
    flag: "BN",
  },
  BOB: {
    symbol: "$b",
    flag: "BO",
  },
  BRL: {
    symbol: "R$",
    flag: "BR",
  },
  BSD: {
    symbol: "$",
    flag: "BS",
  },
  BTN: {
    symbol: "Nu.",
    flag: "BT",
  },
  BWP: {
    symbol: "P",
    flag: "BW",
  },
  BYN: {
    symbol: "Br",
    flag: "BY",
  },
  BZD: {
    symbol: "BZ$",
    flag: "BZ",
  },
  CAD: {
    symbol: "$",
    flag: "CA",
  },
  CDF: {
    symbol: "FC",
    flag: "CD",
  },
  CHF: {
    symbol: "CHF",
    flag: "CH",
  },
  CLP: {
    symbol: "$",
    flag: "CL",
  },
  CNY: {
    symbol: "¥",
    flag: "CN",
  },
  COP: {
    symbol: "$",
    flag: "CO",
  },
  CRC: {
    symbol: "₡",
    flag: "CR",
  },
  CUP: {
    symbol: "₱",
    flag: "CU",
  },
  CVE: {
    symbol: "$",
    flag: "CV",
    decimalPlaces: 0,
  },
  CZK: {
    symbol: "Kč",
    flag: "CZ",
  },
  DJF: {
    symbol: "Fdj",
    flag: "DJ",
    decimalPlaces: 0,
  },
  DKK: {
    symbol: "kr",
    flag: "DK",
  },
  DOP: {
    symbol: "RD$",
    flag: "DO",
  },
  DZD: {
    symbol: "د.ج",
    flag: "DZ",
  },
  EGP: {
    symbol: "£",
    flag: "EG",
  },
  ERN: {
    symbol: "Nfk",
    flag: "ER",
  },
  ETB: {
    symbol: "Br",
    flag: "ET",
  },
  EUR: {
    symbol: "€",
    flag: "EU",
  },
  FJD: {
    symbol: "$",
    flag: "FJ",
  },
  FKP: {
    symbol: "£",
    flag: "FK",
  },
  GBP: {
    symbol: "£",
    flag: "GB",
  },
  GEL: {
    symbol: "₾",
    flag: "GE",
  },
  GGP: {
    symbol: "£",
    flag: "GG",
  },
  GHS: {
    symbol: "₵",
    flag: "GH",
  },
  GIP: {
    symbol: "£",
    flag: "GI",
  },
  GMD: {
    symbol: "D",
    flag: "GM",
  },
  GNF: {
    symbol: "FG",
    flag: "GN",
    decimalPlaces: 0,
  },
  GTQ: {
    symbol: "Q",
    flag: "GT",
  },
  GYD: {
    symbol: "$",
    flag: "GY",
  },
  HKD: {
    symbol: "$",
    flag: "HK",
  },
  HNL: {
    symbol: "L",
    flag: "HN",
  },
  HTG: {
    symbol: "G",
    flag: "HT",
  },
  HUF: {
    symbol: "Ft",
    flag: "HU",
  },
  IDR: {
    symbol: "Rp",
    flag: "ID",
    decimalPlaces: 0,
  },
  ILS: {
    symbol: "₪",
    flag: "IL",
  },
  IMP: {
    symbol: "£",
    flag: "IM",
  },
  INR: {
    symbol: "₹",
    flag: "IN",
  },
  IQD: {
    symbol: "د.ع",
    flag: "IQ",
    decimalPlaces: 3,
  },
  IRR: {
    symbol: "﷼",
    flag: "IR",
  },
  ISK: {
    symbol: "kr",
    flag: "IS",
    decimalPlaces: 0,
  },
  JEP: {
    symbol: "£",
    flag: "JE",
  },
  JMD: {
    symbol: "J$",
    flag: "JM",
  },
  JOD: {
    symbol: "د.ا",
    flag: "JO",
    decimalPlaces: 3,
  },
  JPY: {
    symbol: "¥",
    flag: "JP",
    decimalPlaces: 0,
  },
  KES: {
    symbol: "KSh",
    flag: "KE",
  },
  KGS: {
    symbol: "лв",
    flag: "KG",
  },
  KHR: {
    symbol: "៛",
    flag: "KH",
  },
  KMF: {
    symbol: "CF",
    flag: "KM",
    decimalPlaces: 0,
  },
  KPW: {
    symbol: "₩",
    flag: "KP",
  },
  KRW: {
    symbol: "₩",
    flag: "KR",
    decimalPlaces: 0,
  },
  KWD: {
    symbol: "د.ك",
    flag: "KW",
    decimalPlaces: 3,
  },
  KYD: {
    symbol: "$",
    flag: "KY",
  },
  KZT: {
    symbol: "₸",
    flag: "KZ",
  },
  LAK: {
    symbol: "₭",
    flag: "LA",
  },
  LBP: {
    symbol: "ل.ل",
    flag: "LB",
  },
  LKR: {
    symbol: "₨",
    flag: "LK",
  },
  LRD: {
    symbol: "$",
    flag: "LR",
  },
  LSL: {
    symbol: "L",
    flag: "LS",
  },
  LYD: {
    symbol: "ل.د",
    flag: "LY",
    decimalPlaces: 3,
  },
  MAD: {
    symbol: "د.م.",
    flag: "MA",
  },
  MDL: {
    symbol: "L",
    flag: "MD",
  },
  MGA: {
    symbol: "Ar",
    flag: "MG",
  },
  MKD: {
    symbol: "ден",
    flag: "MK",
  },
  MMK: {
    symbol: "K",
    flag: "MM",
  },
  MNT: {
    symbol: "₮",
    flag: "MN",
  },
  MOP: {
    symbol: "P",
    flag: "MO",
  },
  MRU: {
    symbol: "UM",
    flag: "MR",
  },
  MUR: {
    symbol: "₨",
    flag: "MU",
  },
  MVR: {
    symbol: "Rf",
    flag: "MV",
  },
  MWK: {
    symbol: "MK",
    flag: "MW",
  },
  MXN: {
    symbol: "$",
    flag: "MX",
  },
  MYR: {
    symbol: "RM",
    flag: "MY",
  },
  MZN: {
    symbol: "MT",
    flag: "MZ",
  },
  NAD: {
    symbol: "$",
    flag: "NA",
  },
  NGN: {
    symbol: "₦",
    flag: "NG",
  },
  NIO: {
    symbol: "C$",
    flag: "NI",
  },
  NOK: {
    symbol: "kr",
    flag: "NO",
  },
  NPR: {
    symbol: "₨",
    flag: "NP",
  },
  NZD: {
    symbol: "$",
    flag: "NZ",
  },
  OMR: {
    symbol: "ر.ع.",
    flag: "OM",
    decimalPlaces: 3,
  },
  PAB: {
    symbol: "B/.",
    flag: "PA",
  },
  PEN: {
    symbol: "S/.",
    flag: "PE",
  },
  PGK: {
    symbol: "K",
    flag: "PG",
  },
  PHP: {
    symbol: "₱",
    flag: "PH",
  },
  PKR: {
    symbol: "₨",
    flag: "PK",
  },
  PLN: {
    symbol: "zł",
    flag: "PL",
  },
  PYG: {
    symbol: "₲",
    flag: "PY",
    decimalPlaces: 0,
  },
  QAR: {
    symbol: "ر.ق",
    flag: "QA",
  },
  RON: {
    symbol: "lei",
    flag: "RO",
  },
  RSD: {
    symbol: "дин",
    flag: "RS",
  },
  RUB: {
    symbol: "₽",
    flag: "RU",
  },
  RWF: {
    symbol: "R₣",
    flag: "RW",
    decimalPlaces: 0,
  },
  SAR: {
    symbol: "ر.س",
    flag: "SA",
  },
  SBD: {
    symbol: "$",
    flag: "SB",
  },
  SCR: {
    symbol: "₨",
    flag: "SC",
  },
  SDG: {
    symbol: "ج.س.",
    flag: "SD",
  },
  SEK: {
    symbol: "kr",
    flag: "SE",
  },
  SGD: {
    symbol: "$",
    flag: "SG",
  },
  SHP: {
    symbol: "£",
    flag: "SH",
  },
  SLE: {
    symbol: "Le",
    flag: "SL",
  },
  SOS: {
    symbol: "S",
    flag: "SO",
  },
  SRD: {
    symbol: "$",
    flag: "SR",
  },
  SSP: {
    symbol: "£",
    flag: "SS",
  },
  STN: {
    symbol: "Db",
    flag: "ST",
  },
  SYP: {
    symbol: "ل.س",
    flag: "SY",
  },
  SZL: {
    symbol: "L",
    flag: "SZ",
  },
  THB: {
    symbol: "฿",
    flag: "TH",
  },
  TJS: {
    symbol: "SM",
    flag: "TJ",
  },
  TMT: {
    symbol: "T",
    flag: "TM",
  },
  TND: {
    symbol: "د.ت",
    flag: "TN",
    decimalPlaces: 3,
  },
  TOP: {
    symbol: "T$",
    flag: "TO",
  },
  TRY: {
    symbol: "₺",
    flag: "TR",
  },
  TTD: {
    symbol: "TT$",
    flag: "TT",
  },
  TVD: {
    symbol: "$",
    flag: "TV",
  },
  TWD: {
    symbol: "NT$",
    flag: "TW",
  },
  TZS: {
    symbol: "TSh",
    flag: "TZ",
  },
  UAH: {
    symbol: "₴",
    flag: "UA",
  },
  UGX: {
    symbol: "USh",
    flag: "UG",
    decimalPlaces: 0,
  },
  USD: {
    symbol: "$",
    flag: "US",
  },
  UYU: {
    symbol: "$U",
    flag: "UY",
  },
  UZS: {
    symbol: "лв",
    flag: "UZ",
  },
  VEF: {
    symbol: "Bs",
    flag: "VE",
  },
  VND: {
    symbol: "₫",
    flag: "VN",
    decimalPlaces: 0,
  },
  VUV: {
    symbol: "VT",
    flag: "VU",
    decimalPlaces: 0,
  },
  WST: {
    symbol: "WS$",
    flag: "WS",
  },
  XAF: {
    symbol: "FCFA",
    flag: "CM",
    decimalPlaces: 0,
  },
  XCD: {
    symbol: "$",
    flag: "LC",
  },
  XOF: {
    symbol: "CFA",
    flag: "CI",
    decimalPlaces: 0,
  },
  XPF: {
    symbol: "F",
    flag: "PF",
    decimalPlaces: 0,
  },
  YER: {
    symbol: "﷼",
    flag: "YE",
  },
  ZAR: {
    symbol: "R",
    flag: "ZA",
  },
  ZMW: {
    symbol: "ZK",
    flag: "ZM",
  },
  ZWL: {
    symbol: "Z$",
    flag: "ZW",
  },
};

export default currencies;

export function formatCurrency(
  money: Money,
  locale: string = navigator.language
): string {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: money.currency,
  }).format(money.amount);
}

export function decimalPlacesForCurrency(currency: CurrencyIso): number {
  return currencies[currency].decimalPlaces ?? 2;
}

export function roundMoney(money: Money): Money {
  // Round to the number of decimals supported by the currency
  const decimalPlaces = currencies[money.currency].decimalPlaces ?? 2;
  const fraction = Math.pow(10, decimalPlaces);
  const amount = Math.round(money.amount * fraction) / fraction;

  return {
    ...money,
    amount,
  };
}
