export interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  size?: "small" | "regular" | "large";
  variant?: "primary" | "secondary" | "danger" | "white" | "outlineDanger";
}

const SIZE_CLASSES = {
  small: "rounded px-2.5 py-1.5 text-xs",
  regular: "rounded-md px-4 py-2 text-sm",
  large: "rounded-md px-4 py-2 text-base",
};

const VARIANT_CLASSES = {
  primary:
    "border-transparent text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500",
  secondary:
    "border-transparent text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:ring-indigo-500",
  danger:
    "border-transparent text-white bg-red-600 hover:bg-red-700 focus:ring-red-500",
  white:
    "border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:ring-indigo-500",
  outlineDanger:
    "border-red-500 text-red-700 bg-white hover:bg-gray-50 focus:ring-red-500",
};

export function ButtonClassNames({ size, variant }: ButtonProps) {
  return `
    inline-flex
    items-center
    border
    font-medium
    shadow-sm
    focus:outline-none focus:ring-2 focus:ring-offset-2
    ${SIZE_CLASSES[size ?? "regular"]}
    ${VARIANT_CLASSES[variant ?? "primary"]}
  `;
}

export default function Button(props: ButtonProps) {
  const { children, type, className, ...rest } = props;

  return (
    <button
      type={type ?? "button"}
      className={`
        ${ButtonClassNames(props)}
        ${className ?? ""}`}
      {...rest}
    >
      {children}
    </button>
  );
}
