enum IncomeFrequency {
  Hourly,
  Daily,
  Weekly,
  Monthly,
  Quarterly,
  TwiceYearly,
  Yearly,
}

export default IncomeFrequency;
