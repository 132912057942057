import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import Ownership from "./Ownership";
import { RootState } from "../../store";

export default function CompaniesShow() {
  const companyId = useParams().companyId;
  if (!companyId) {
    return null;
  }

  const company = useSelector((state: RootState) => state.companies[companyId]);
  // const { t } = useTranslation("companies");

  return (
    <>
      <h1 className="text-3xl font-medium leading-6 mr-auto">{company.name}</h1>

      <Ownership company={company} />
      <Outlet />
    </>
  );
}
