import IClock from "./IClock";

export default class Clock implements IClock {
  elapsedMonths = 0;

  constructor(
    public readonly firstYear: number,
    public readonly firstMonth: number
  ) {
    if (firstMonth < 0 || firstMonth > 11) {
      throw new Error("Month must be between 0 and 11");
    }
  }

  reset() {
    this.elapsedMonths = 0;
  }

  monthTick() {
    this.elapsedMonths += 1;
  }

  get currentMonth(): number {
    return (this.firstMonth + this.elapsedMonths) % 12;
  }

  get currentYear(): number {
    return (
      this.firstYear + Math.floor((this.firstMonth + this.elapsedMonths) / 12)
    );
  }
}
