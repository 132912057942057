import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  LogarithmicScale,
  PointElement,
  // Ticks,
  // TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";

// import Company from "../finsim/Company"
// import Person from "../finsim/Person";
import World from "../finsim/World";

import { RootState } from "../store";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,

  scales: {
    y: {
      type: "linear",
      position: "right",
      ticks: {
        callback: function (value: number) {
          if (value >= 1000000000000) {
            return (Math.round(value / 10000000000) / 100).toString() + "t";
          }
          if (value >= 1000000000) {
            return (Math.round(value / 10000000) / 100).toString() + "b";
          }
          if (value >= 1000000) {
            return (Math.round(value / 10000) / 100).toString() + "m";
          }
          if (value >= 1000) {
            return (Math.round(value / 10) / 100).toString() + "k";
          }
          return value.toFixed();
        },
      },
    },
    x: {
      display: false,
    },
  },
};

const useSimulation = () => {
  const state = useSelector((state: RootState) => state);
  const simulation = World.deserialize(state);
  const cash: number[] = [];

  for (let i = 0; i < 360; i++) {
    simulation.monthTick();
    cash.push(simulation.currentFinanceState.cash);
  }

  return cash;
};

export default function Chart() {
  const simulation = useSimulation();

  const [data, setData] = useState<ChartData<"line">>({ datasets: [] });

  const chartRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    const chart = chartRef.current;

    if (chart) {
      // @ts-expect-error blah
      const context = chart.ctx as CanvasRenderingContext2D;
      console.log("context", context);
      const gradient = context.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, "rgba(0,255,0,1)");
      gradient.addColorStop(1, "rgba(255,255,0,0.1)");

      const neg = context.createLinearGradient(0, 0, 0, 400);
      neg.addColorStop(0, "rgba(0,0,0,0)");
      neg.addColorStop(1, "rgba(128,0,0,.8)");

      setData({
        labels: Array.from(Array(simulation.length).keys()),
        datasets: [
          {
            fill: {
              target: "origin",
              above: gradient,
              below: neg,
            },
            label: "Net worth",
            data: simulation,
            borderColor: "rgb(53, 162, 235)",
            // backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      });
    }
  }, []);

  return (
    <div>
      <h1>Chart</h1>
      {/* @ts-expect-error I cannot get the options to pass type checks */}
      <Line ref={chartRef} data={data} options={options} />
    </div>
  );
}
