import React from "react";

type LabelProps = React.ComponentPropsWithoutRef<"label">;

const Label = React.forwardRef(
  (props: LabelProps, ref: React.ForwardedRef<HTMLLabelElement>) => {
    const { children, className, ...rest } = props;

    return (
      <label
        ref={ref}
        className={`block text-sm font-medium text-gray-700 dark:text-white ${
          className ?? ""
        }`}
        {...rest}
      >
        {children}
      </label>
    );
  }
);

export default Label;
