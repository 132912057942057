import { Controller, useFormContext } from "react-hook-form";

import { useTranslation } from "react-i18next";

import InputField from "../../components/fields/Field";
import Select from "../../components/fields/Select";
import { CurrencyField } from "../../components/useForm";

import { GrowthStrategy } from "../../store/types";

interface Form {
  growthStrategy: GrowthStrategy;
}

export default function GrowthSubform() {
  const { register, control, watch } = useFormContext<Form>();

  const common = useTranslation("common").t;

  const type = watch("growthStrategy.type", "null");

  return (
    <div className="my-5 rounded-md bg-gray-100 dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
      <h3
        className="
        px-5 py-3
        rounded-t
        text-md
        font-semibold
        text-gray-700
        dark:text-gray-200
        bg-gray-700
        "
      >
        Growth strategy
      </h3>
      <div className="flex gap-3 px-5 py-5">
        <div className="flex-1">
          <Controller
            render={({ field }) => (
              <Select
                id="income-growthStrategy"
                className="flex-1"
                label={common("growthStrategyForm.type")}
                description={common("growthStrategyForm.typeDescription")}
                options={[
                  { id: "null", name: common("growthStrategies.null") },
                  {
                    id: "percent",
                    name: common("growthStrategies.percent"),
                  },
                  {
                    id: "inflation",
                    name: common("growthStrategies.inflation"),
                  },
                  {
                    id: "fixed",
                    name: common("growthStrategies.fixed"),
                  },
                ]}
                {...field}
              />
            )}
            control={control}
            name="growthStrategy.type"
          />
        </div>
        <div className="flex-1">
          {type === "percent" && (
            <InputField
              id="income-growthPercent"
              className="flex-1"
              label={common("growthStrategyForm.percent")}
              {...register("growthStrategy.percent", {
                valueAsNumber: true,
              })}
            />
          )}
          {type === "fixed" && (
            <CurrencyField
              id="income-growthFixed"
              currency={"EUR"}
              className="flex-1"
              label={common("growthStrategyForm.amount")}
              description={common("growthStrategyForm.amount")}
              name="growthStrategy.amount"
              rules={{}}
            />
          )}
          {type === "inflation" && (
            <div className="flex gap-3">
              <InputField
                id="income-growthInflationMultiplier"
                className="flex-1"
                label={common("growthStrategyForm.multiply")}
                {...register("growthStrategy.multiply", {
                  valueAsNumber: true,
                })}
              />
              <InputField
                id="income-growthInflationAdd"
                className="flex-1"
                label={common("growthStrategyForm.add")}
                {...register("growthStrategy.add", {
                  valueAsNumber: true,
                })}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
