import { Income as IncomeState } from "../../store/incomes";
import { Frequency } from "../../store/types";

import Company from "../Company";
import deserializeGrowthStrategy from "../GrowthStrategies/GrowthStrategyFactory";
import IGrowthStrategy from "../GrowthStrategies/IGrowthStrategy";
import NullGrowthStrategy from "../GrowthStrategies/NullGrowthStrategy";
import IIncome from "../IIncome";
import Person from "../Person";
import TimestampRange from "../Timestamps/TimestampRange";

export enum SalaryFrequency {
  Hourly,
  Daily,
  Weekly,
  Monthly,
  Yearly,
}

function frequencyToEnum(value: Frequency): SalaryFrequency {
  switch (value) {
    case "hourly":
      return SalaryFrequency.Hourly;
    case "daily":
      return SalaryFrequency.Daily;
    case "weekly":
      return SalaryFrequency.Weekly;
    case "monthly":
      return SalaryFrequency.Monthly;
    case "yearly":
      return SalaryFrequency.Yearly;
    default:
      throw new Error(`Invalid salary frequency: ${value}`);
  }
}

export default class Salary extends IIncome {
  schedule: TimestampRange = TimestampRange.always();

  /**
   * Salary for a "frequency" period
   */
  currentAmount: number;

  /**
   * This is not how frequently the salary is paid (we always assume a month),
   * but rather the size of the period that the currentAmount covers.
   * For example, if the currentAmount is $1000 and the frequency is "Monthly",
   * then the salary is $1000 per month.
   */
  frequency: SalaryFrequency = SalaryFrequency.Yearly;

  /**
   * Whether the salary is taxed by the employer before being paid to the employee.
   */
  taxedAtSource = true;

  /**
   * Percentage of the salary that is paid in a pension.
   * This reduces the taxable amount and the net amount paid to the employee.
   */
  employeePensionContributionRate = 0;

  /**
   * Percentage of the salary that is paid in a pension.
   * This is paid by the employer additionally to the employee's salary.
   */
  employerPensionContributionRate = 0;

  /**
   * We update the salary amount every 12 working months.
   * The strategy determines how the salary amount changes over time.
   */
  growthStrategy: IGrowthStrategy = new NullGrowthStrategy();

  private monthsAtEmployer = 0;

  constructor(
    public readonly name: string,
    public readonly initialAmount: number,
    public readonly personId: string,
    public readonly company?: Company
  ) {
    super();
    this.currentAmount = initialAmount;
  }

  static deserialize(data: IncomeState): Salary {
    if (!data.personId) {
      throw new Error("Person ID not set");
    }

    const salary = new Salary(
      data.id,
      data.initialAmount.amount,
      data.personId
    );
    salary.frequency = frequencyToEnum(data.frequency);
    salary.growthStrategy = deserializeGrowthStrategy(data.growthStrategy);

    console.log("SO", salary);

    return salary;
  }

  monthTick() {
    if (!this.schedule.isCurrent(this.getWorld().clock)) {
      return;
    }

    this.monthsAtEmployer += 1;

    this.paySalary();

    if (this.monthlyTotalPensionContribution > 0) {
      this.person.addPensionContribution(this.monthlyTotalPensionContribution);
    }

    if (this.monthsAtEmployer % 12 === 0) {
      this.grow();
    }
  }

  private grow() {
    this.currentAmount = this.growthStrategy.change(this.currentAmount);
  }

  private paySalary() {
    if (this.taxedAtSource) {
      this.person.addTaxedIncome(
        this.monthlyTaxableAmount,
        this.monthlyIncomeTax
      );
    } else {
      this.person.addIncome(this.monthlyTaxableAmount);
    }

    if (this.company) {
      this.company.addSalaryExpense(this, this.monthlyAmount);
    }
  }

  get yearlyGrossAmount(): number {
    switch (this.frequency) {
      case SalaryFrequency.Yearly:
        return this.currentAmount;
      case SalaryFrequency.Monthly:
        return this.currentAmount * 12;
      case SalaryFrequency.Weekly:
        // Follow the same logic as below
        return this.currentAmount * 46;
      case SalaryFrequency.Daily:
        // Assume an average of 19 work days per month

        // 365 -
        //  52 * 2 - # weekends
        //  25 -     # holidays
        //   8 =     # bank holidays
        // 228 working days in a year
        // 228 / 12 = 19
        return this.currentAmount * 19 * 12;
      case SalaryFrequency.Hourly:
        return this.currentAmount * 8 * 19 * 12;
    }
  }

  get monthlyAmount(): number {
    return this.yearlyGrossAmount / 12;
  }

  get yearlyTaxableAmount(): number {
    return this.yearlyGrossAmount - this.yearlyEmployeePensionContribution;
  }

  get monthlyTaxableAmount(): number {
    return this.monthlyAmount - this.monthlyEmployeePensionContribution;
  }

  get yearlyEmployeePensionContribution(): number {
    return this.yearlyGrossAmount * this.employeePensionContributionRate;
  }

  get monthlyEmployeePensionContribution(): number {
    return this.yearlyEmployeePensionContribution / 12;
  }

  get yearlyEmployerPensionContribution(): number {
    return this.yearlyGrossAmount * this.employerPensionContributionRate;
  }

  get monthlyEmployerPensionContribution(): number {
    return this.yearlyEmployerPensionContribution / 12;
  }

  get yearlyTotalPensionContribution(): number {
    return (
      this.yearlyEmployeePensionContribution +
      this.yearlyEmployerPensionContribution
    );
  }

  get monthlyTotalPensionContribution(): number {
    return (
      this.monthlyEmployeePensionContribution +
      this.monthlyEmployerPensionContribution
    );
  }

  get yearlyIncomeTax(): number {
    return this.person.incomeTaxFor(this.yearlyTaxableAmount);
  }

  get monthlyIncomeTax(): number {
    return this.yearlyIncomeTax / 12;
  }

  private get person(): Person {
    const person = this.getWorld().getPerson(this.personId);
    if (!person) {
      throw new Error("Person not found");
    }

    return person;
  }
}
