import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button, { ButtonClassNames } from "../../components/atoms/Button";
import { RootState } from "../../store";
import { Company } from "../../store/companies";
import { CompanyOwnership, removed } from "../../store/companyOwnerships";

export interface OwnershipProps {
  company: Company;
}

function sharePercentage(share: number, total: number) {
  return Math.round((share / total) * 10000) / 100;
}

export default function Ownership(props: OwnershipProps) {
  const { company } = props;
  const allOwnerships = useSelector(
    (state: RootState) => state.companyOwnerships
  );
  const people = useSelector((state: RootState) => state.people);

  const ownerships = company.ownershipIds.map((id: string) => {
    const ownership = allOwnerships[id];
    const person = ownership.personId ? people[ownership.personId] : null;
    return {
      ...ownership,
      person,
    };
  });

  const totalShares = ownerships.reduce((sum, owner) => sum + owner.shares, 0);

  const { t } = useTranslation("companies");

  const dispatch = useDispatch();
  const remove = (ownership: CompanyOwnership) => {
    dispatch(removed(ownership));
  };

  return (
    <div>
      <h1>Company Ownership</h1>

      <div className="text-sm mb-3 bg-teal-100 p-3 rounded-md">
        {t("ownership.description")}
      </div>

      <table className="table-auto w-full">
        {ownerships.map((ownership) => {
          const { person, shares } = ownership;
          return (
            <tr>
              <td className="p-3 pl-0">
                <div>{person ? person.name : "Another person"}</div>
                <div className="text-sm text-gray-500">{shares} shares</div>
              </td>
              <td className="p-3">{sharePercentage(shares, totalShares)}%</td>
              <td className="p-3 pr-0 text-right">
                <Link
                  to={`/companies/${company.id}/ownerships/${ownership.id}`}
                  className={ButtonClassNames({
                    size: "small",
                    variant: "secondary",
                  })}
                >
                  Edit
                </Link>
                <Button
                  variant="outlineDanger"
                  size="small"
                  className="ml-2"
                  onClick={() => remove(ownership)}
                >
                  Remove
                </Button>
              </td>
            </tr>
          );
        })}
      </table>
      <Link to="ownerships/new">Add ownership</Link>
    </div>
  );
}
