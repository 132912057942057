import World from "./World";

export default abstract class IIncome {
  world: World | null = null;

  protected getWorld(): World {
    if (!this.world) {
      throw new Error("World not set");
    }
    return this.world;
  }

  abstract monthTick(): void;
}
