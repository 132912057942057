import IExpense from "./IExpense";
import IIncome from "./IIncome";
import Salary from "./Incomes/Salary";
import { IIncomeReceiver } from "./ISalaryReceiver";
import NullTax from "./Taxes/Calculators/NullTax";
import ICorporationTax from "./Taxes/ICorporationTaxCalculator";
import ITaxCalculator from "./Taxes/ITaxCalculator";
import World from "./World";

import { CountryIso } from "../Countries";
import { RootState } from "../store";
import { Company as CompanyState } from "../store/companies";

export default class Company implements IIncomeReceiver {
  public world: World | null = null;

  ownership: { id: string; personId: string | null; shares: number }[] = [];
  corporationTaxCalculator: ICorporationTax = new NullTax();
  employmentTaxes: ITaxCalculator[] = [];

  firstMonthOfAccountingYear = 0;
  incomeSources: IIncome[] = [];
  expenseSources: IExpense[] = [];
  salaries: Salary[] = [];
  cashWithholdRatio = 0.1;

  cashBalance = 0;
  hasBankupted = false;

  revenueForThisYear = 0;
  costForThisYear = 0;
  taxForecastForThisYear = 0;

  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly countryCode: CountryIso
  ) {}

  serialize(): CompanyState {
    return {
      id: this.id,
      name: this.name,
      countryCode: this.countryCode,
      ownershipIds: this.ownership.map((o) => o.id),
    };
  }

  static deserialize(data: CompanyState, state: RootState): Company {
    const company = new Company(data.id, data.name, data.countryCode);

    for (const ownershipId of data.ownershipIds) {
      const ownership = state.companyOwnerships[ownershipId];
      company.ownership.push({
        id: ownershipId,
        personId: ownership.personId,
        shares: ownership.shares,
      });
    }

    return company;
  }

  monthTick() {
    if (this.hasBankupted) {
      return;
    }

    for (const source of this.incomeSources) {
      source.monthTick();
    }

    for (const source of this.expenseSources) {
      source.monthTick();
    }

    this.paySalaries();

    this.taxForecastForThisYear = this.corporationTaxCalculator.calculate(
      this.revenueForThisYear - this.costForThisYear
    );

    console.log(
      this.getWorld().clock.currentMonth,
      this.firstMonthOfAccountingYear
    );
    if (
      this.getWorld().clock.currentMonth === this.firstMonthOfAccountingYear
    ) {
      this.payCorporationTax();
      this.revenueForThisYear = 0;
      this.costForThisYear = 0;
    }

    this.payDividends();
  }

  addIncome(amount: number) {
    if (this.hasBankupted) {
      return;
    }

    this.cashBalance += amount;
    this.revenueForThisYear += amount;
  }

  addExpense(amount: number) {
    if (this.hasBankupted) {
      return;
    }

    this.cashBalance -= amount;
    this.costForThisYear += amount;

    if (this.cashBalance < 0) {
      this.hasBankupted = true;
    }
  }

  addSalaryExpense(salary: Salary, amount: number) {
    if (this.hasBankupted) {
      return;
    }

    console.log("Adding salary expense of ", amount);

    this.addExpense(amount);
    if (salary.monthlyEmployerPensionContribution > 0) {
      console.log(
        "Adding pension expense of ",
        salary.monthlyEmployerPensionContribution
      );
      this.addExpense(salary.monthlyEmployerPensionContribution);
    }

    for (const tax of this.employmentTaxes) {
      const taxAmount = tax.calculate(amount * 12) / 12;
      if (taxAmount > 0) {
        console.log("Adding tax expense of ", taxAmount);
        this.addExpense(taxAmount);
      }
    }
  }

  paySalaries() {
    for (const salary of this.salaries) {
      salary.monthTick();
    }
  }

  payCorporationTax() {
    if (this.hasBankupted) {
      return;
    }

    const tax = this.corporationTaxCalculator.calculate(
      this.revenueForThisYear - this.costForThisYear
    );

    console.log("Total revenue for this year: ", this.revenueForThisYear);
    console.log("Total cost for this year: ", this.costForThisYear);
    console.log("Paying corporation tax of ", tax);

    this.cashBalance -= tax;
    this.taxForecastForThisYear = 0;

    if (this.cashBalance < 0) {
      this.hasBankupted = true;
    }
  }

  payDividends() {
    if (this.hasBankupted) {
      return;
    }

    console.log("Paying dividends of ", this.cashBalance);

    // The amount of cash that is available to pay dividends is the cash balance
    // minus the amount of cash that is being withheld for corporation tax and
    // an extra percentage for safety.
    const totalDividend =
      this.cashBalance -
      this.taxForecastForThisYear * (1 + this.cashWithholdRatio);

    for (const owner of this.ownership) {
      const person = this.getWorld().getPerson(owner.personId);
      if (person) {
        const ratio = owner.shares / this.totalShares();
        person.addDividendIncome(totalDividend * ratio);
      }
    }

    console.log("Paid dividends of ", totalDividend);

    this.cashBalance -= totalDividend;
  }

  totalShares() {
    return this.ownership.reduce((total, o) => total + o.shares, 0);
  }

  private getWorld(): World {
    if (this.world === null) {
      throw new Error("World not set");
    }
    return this.world;
  }
}
