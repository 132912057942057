import CompaniesList from "./List";
import CompaniesNew from "./New";
import OwnershipForm from "./OwnershipForm";
import CompaniesShow from "./Show";

export default [
  {
    path: "/companies",
    element: <CompaniesList />,
    children: [
      {
        path: "new",
        element: <CompaniesNew />,
      },
    ],
  },
  {
    path: "/companies/:companyId",
    element: <CompaniesShow />,
    children: [
      {
        path: "ownerships/new",
        element: <OwnershipForm />,
      },
      {
        path: "ownerships/:ownershipId",
        element: <OwnershipForm />,
      },
    ],
  },
];
