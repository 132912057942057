import Clock from "./Clock/Clock";
import IClock from "./Clock/IClock";
import Company from "./Company";
import FinanceState from "./FinanceState";
import IExpense from "./IExpense";
import IIncome from "./IIncome";
import GenericIncome from "./Incomes/GenericIncome";
import Salary from "./Incomes/Salary";
import IInflationProvider from "./Inflation/IInflationProvider";
import StaticInflation from "./Inflation/StaticInflation";
import Person from "./Person";

import { RootState } from "../store";

export default class World {
  clock: IClock = new Clock(new Date().getFullYear(), new Date().getMonth());

  initialFinanceState: FinanceState = new FinanceState();
  currentFinanceState: FinanceState = Object.assign(
    {},
    this.initialFinanceState
  );

  reset() {
    this.clock.reset();
    this.companies = [];
    this.people = [];
    this.incomeSources = [];
  }

  inflationProvider: IInflationProvider = new StaticInflation(0.02);

  get currentInflation(): number {
    return this.inflationProvider.getInflation(this.clock);
  }

  /**
   * The month during which we sum up and pay the total income tax.
   * A refund is paid if the tax paid is over the tax due.
   */
  firstMonthOfTaxYear = 0;

  /**
   * The age after which a person will start receiving a state pension.
   */
  stateRetirementAge = 65;

  companies: Company[] = [];
  people: Person[] = [];

  addCompany(company: Company) {
    company.world = this;
    this.companies.push(company);
  }

  addPerson(person: Person) {
    person.world = this;
    this.people.push(person);
  }

  getCompany(id: string): Company | undefined {
    return this.companies.find((c) => c.id === id);
  }

  getPerson(id: string | null): Person | undefined {
    return this.people.find((p) => p.id === id);
  }

  incomeSources: IIncome[] = [];
  expenseSources: IExpense[] = [];

  addIncomeSource(source: IIncome) {
    source.world = this;
    this.incomeSources.push(source);
  }

  addExpenseSource(source: IExpense) {
    this.expenseSources.push(source);
  }

  static deserialize(state: RootState): World {
    const world = new World();

    // TODO: Set the clock to a custom start date
    // world.clock = Clock.deserialize(state.clock);

    Object.values(state.people).forEach((p) => {
      world.addPerson(Person.deserialize(p));
    });

    Object.values(state.companies).forEach((c) => {
      world.addCompany(Company.deserialize(c, state));
    });

    Object.values(state.incomes).forEach((i) => {
      switch (i.type) {
        case "salary":
          world.addIncomeSource(Salary.deserialize(i));
          break;
        case "generic":
          world.addIncomeSource(GenericIncome.deserialize(i));
          break;
      }
    });

    return world;
  }

  currentMonthIncome = 0;
  currentMonthExpenses = 0;

  monthTick() {
    for (const company of this.companies) {
      company.monthTick();
    }

    // Run a tick for each income source
    for (const source of this.incomeSources) {
      source.monthTick();
    }

    // Run a tick for each expense source
    for (const source of this.expenseSources) {
      source.monthTick();
    }

    if (this.clock.currentMonth === this.firstMonthOfTaxYear) {
      this.payTaxes();
    }

    this.updateFinanceState();
    this.clock.monthTick();
  }

  receiveIncome(income: number) {
    this.currentMonthIncome += income;
  }

  receiveExpense(expense: number) {
    this.currentMonthExpenses += expense;
  }

  payTaxes() {
    const totalIncomeTax = this.people.reduce((total, person) => {
      const totalTax = person.incomeTaxFor(person.taxableIncome);
      const paidTaxes = person.paidTaxes;

      person.taxableIncome = 0;
      person.paidTaxes = 0;

      return total + totalTax - paidTaxes;
    }, 0);

    if (totalIncomeTax < 0) {
      console.log(`Refunding ${totalIncomeTax} to the people`);
      this.receiveIncome(-totalIncomeTax);
    } else {
      this.receiveExpense(totalIncomeTax);
    }
  }

  updateFinanceState() {
    this.currentFinanceState.cash +=
      this.currentMonthIncome - this.currentMonthExpenses;

    this.currentMonthIncome = 0;
    this.currentMonthExpenses = 0;
  }
}
