import BeginningOfSimulation from "./BeginningOfSimulation";
import EndOfSimulation from "./EndOfSimulation";
import ITimestamp from "./ITimestamp";
import Clock from "../Clock/Clock";

export default class TimestampRange {
  private hasBegun = false;
  private hasEnded = false;

  constructor(
    public readonly begin: ITimestamp,
    public readonly end: ITimestamp
  ) {}

  isCurrent(clock: Clock): boolean {
    if (this.begin.isPast(clock)) {
      this.hasBegun = true;
    }

    if (this.end.isPast(clock)) {
      this.hasEnded = true;
    }

    return this.hasBegun && !this.hasEnded;
  }

  static always(): TimestampRange {
    return new TimestampRange(
      new BeginningOfSimulation(),
      new EndOfSimulation()
    );
  }
}
