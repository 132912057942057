import IGrowthStrategy from "./IGrowthStrategy";

export default class PercentGrowthStrategy implements IGrowthStrategy {
  readonly percent: number;

  constructor(percent: number) {
    this.percent = percent;
  }

  change(amount: number): number {
    console.log("AM", typeof amount, "GR", this.percent);

    return amount * (1 + this.percent);
  }
}
