import FixedGrowthStrategy from "./FixedGrowthStrategy";
import IGrowthStrategy from "./IGrowthStrategy";
import InflationGrowthStrategy from "./InflationGrowthStrategy";
import NullGrowthStrategy from "./NullGrowthStrategy";
import PercentGrowthStrategy from "./PercentGrowthStrategy";

import { GrowthStrategy } from "../../store/types";

export default function deserializeGrowthStrategy(
  data: GrowthStrategy
): IGrowthStrategy {
  switch (data.type) {
    case "null":
      return new NullGrowthStrategy();
    case "fixed":
      return new FixedGrowthStrategy(data.amount ?? 0);
    case "percent":
      return new PercentGrowthStrategy(data.percent ?? 0);
    case "inflation":
      return new InflationGrowthStrategy(data.add ?? 0, data.multiply ?? 1);
  }
}
