import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useState } from "react";
import { FieldError } from "react-hook-form";
import Label from "./Label";

export interface Option {
  id: string | null;
  name: string;
}

export interface SelectProps<T extends Option> {
  id: string;
  name?: string;
  label: React.ReactNode;
  description?: string;
  placeholder?: string;
  options: (T | null)[];
  value?: string | null;
  error?: FieldError;
  className?: string;
  renderHeader?: (selected: T | null) => JSX.Element;
  renderOption?: (
    option: T | null,
    selected: boolean,
    active: boolean
  ) => JSX.Element;
  onChange: (option: string | null) => void;
}

export default React.forwardRef(
  <T extends Option>(
    props: SelectProps<T>,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const [selected, setSelected] = useState<null | T>(
      typeof props.value === "undefined"
        ? null
        : props.options.find((o) => o?.id === props.value) ?? null
    );

    const {
      id,
      name,
      label,
      description,
      placeholder,
      options,
      error,
      className,
      renderHeader,
      renderOption,
    } = props;

    const isInvalid = error !== undefined;

    const onChange = (option: T | null) => {
      setSelected(option);
      props.onChange(option?.id ?? null);
    };

    const classes = isInvalid
      ? "border-red-300 focus:border-red-500 focus:ring-red-500"
      : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 dark:focus:border-green-300 dark:focus:ring-green-300";

    return (
      <Listbox
        as="div"
        id={id}
        name={name}
        value={selected}
        onChange={onChange}
        className={className}
      >
        <Listbox.Label as={Label}>{label}</Listbox.Label>
        <div className="relative mt-1">
          <Listbox.Button
            ref={ref}
            className={`
            relative
            w-full
            cursor-default
            rounded-md
            border
            bg-white
            dark:bg-slate-700
            dark:text-white
            py-2 pl-3 pr-10
            text-left
            shadow-sm
            sm:text-sm
            focus:ring-1
            focus:outline-none ${classes}`}
          >
            <span className="inline-flex w-full truncate">
              {selected ? (
                renderHeader ? (
                  renderHeader(selected)
                ) : (
                  <span className="truncate">{selected.name}</span>
                )
              ) : (
                <span className="text-gray-500">{placeholder}</span>
              )}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className="
              absolute
              z-10
              mt-1
              max-h-60
              min-w-full w-auto
              whitespace-nowrap
              overflow-auto
              rounded-md shadow-lg
              bg-white dark:bg-slate-700
              text-gray-900 dark:text-white
              py-1
              text-sm
              ring-1 ring-black ring-opacity-5
              focus:outline-none"
            >
              {options.map((option) => {
                return (
                  <Listbox.Option
                    key={option?.id ?? "null"}
                    className={({ active }) =>
                      `${
                        active ? "text-white bg-indigo-600" : ""
                      } relative cursor-default select-none py-2 pl-3 pr-9`
                    }
                    value={option}
                  >
                    {({ selected, active }) =>
                      renderOption ? (
                        renderOption(option, selected, active)
                      ) : (
                        <div className="flex items-center">
                          <span className="block truncate">{option?.name}</span>
                        </div>
                      )
                    }
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
        </div>
        {error ? (
          <p className="mt-1 text-sm text-red-600" id={`${id}-error`}>
            {error.message}
          </p>
        ) : (
          description && (
            <p
              className="mt-1 text-sm text-gray-500 dark:text-gray-400"
              id={`${id}-description`}
            >
              {description}
            </p>
          )
        )}
      </Listbox>
    );
  }
);
