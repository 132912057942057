import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CompanyOwnership {
  id: string;
  companyId: string;
  personId: string | null;
  shares: number;
}

export type CompanyOwnershipsState = Record<string, CompanyOwnership>;

const initialState: CompanyOwnershipsState = {};

export const companyOwnershipsSlice = createSlice({
  name: "companyOwnerships",
  initialState,
  reducers: {
    added: (state, action: PayloadAction<CompanyOwnership>) => {
      state[action.payload.id] = action.payload;
    },

    updated: (state, action: PayloadAction<CompanyOwnership>) => {
      state[action.payload.id] = action.payload;
    },

    removed: (state, action: PayloadAction<CompanyOwnership>) => {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete state[action.payload.id];
    },
  },
});

export const { added, updated, removed } = companyOwnershipsSlice.actions;
export default companyOwnershipsSlice.reducer;
