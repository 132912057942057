import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GrowthStrategy, Money, Tax, TimestampRange } from "./types";
import { CountryIso } from "../Countries";

interface Mortgage {
  principal: Money;
  interestRate: number;
  repaymentPeriod: number;
}

type AssetAmount =
  | {
      type: "percent";
      percent: number;
    }
  | {
      type: "fixed";
      amount: Money;
      growthStrategy: GrowthStrategy;
    };

export type Asset = {
  id: string;
  name: string;
  schedule: TimestampRange;

  value: Money;
  growthStrategy: GrowthStrategy;
  pricePaid: Money;
} & (
  | {
      type: "property";

      countryCode: CountryIso;
      ownershipIds: string[];

      mortgage: Mortgage;
      rent: AssetAmount;

      maintenanceCost: AssetAmount;
      insuranceCost: AssetAmount;

      propertyTax: Tax;
      rentTax:
        | {
            isIncomeTaxed: true;
          }
        | ({
            isIncomeTaxed: false;
          } & Tax);
    }
  | {
      type: "generic";
    }
);

export type AssetsState = Record<string, Asset>;

const initialState: AssetsState = {};

export const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    added: (state, action: PayloadAction<Asset>) => {
      state[action.payload.id] = action.payload;
    },

    updated: (state, action: PayloadAction<Asset>) => {
      state[action.payload.id] = action.payload;
    },

    removed: (state, action: PayloadAction<Asset>) => {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete state[action.payload.id];
    },
  },
});

export const { added, updated, removed } = assetsSlice.actions;
export default assetsSlice.reducer;
