import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./404Page";
import reportWebVitals from "./reportWebVitals";
import "./app.css";

import Chart from "./routes/Chart";
import companiesRoutes from "./routes/Companies";
import peopleRoutes from "./routes/People";
import Root from "./routes/Root";

import { store } from "./store";

import "./i18n";

const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Root element not found");
}

const root = ReactDOM.createRoot(rootElement);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/chart",
        element: <Chart />,
      },
      ...companiesRoutes,
      ...peopleRoutes,
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

reportWebVitals(console.log);
