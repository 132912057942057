import ISalaryReceiver from "./ISalaryReceiver";
import NullTax from "./Taxes/Calculators/NullTax";
import ITaxCalculator from "./Taxes/ITaxCalculator";
import World from "./World";

import { Person as PersonState } from "../store/people";

export default class Person implements ISalaryReceiver {
  public world: World | null = null;

  public incomeTaxCalculator: ITaxCalculator = new NullTax();

  public pensionContributions = 0;
  public taxableIncome = 0;
  public dividendIncome = 0;
  public paidTaxes = 0;

  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly birthYear: number,
    public readonly birthMonth: number
  ) {}

  serialize(): PersonState {
    return {
      id: this.id,
      name: this.name,
      birthYear: this.birthYear,
      birthMonth: this.birthMonth,
    };
  }

  static deserialize(data: PersonState): Person {
    return new Person(data.id, data.name, data.birthYear, data.birthMonth);
  }

  age(): number {
    const clock = this.getWorld().clock;
    if (clock.currentMonth < this.birthMonth) {
      return clock.currentYear - this.birthYear - 1;
    }
    return clock.currentYear - this.birthYear;
  }

  incomeTaxFor(amount: number): number {
    return this.incomeTaxCalculator.calculate(amount);
  }

  addPensionContribution(amount: number) {
    this.pensionContributions += amount;
  }

  addIncome(amount: number) {
    this.taxableIncome += amount;
    this.getWorld().receiveIncome(amount);
  }

  addDividendIncome(amount: number) {
    this.dividendIncome += amount;
    this.getWorld().receiveIncome(amount);
  }

  addTaxedIncome(grossAmount: number, tax: number) {
    this.paidTaxes += tax;
    this.taxableIncome += grossAmount;
    this.getWorld().receiveIncome(grossAmount - tax);
  }

  private getWorld(): World {
    if (this.world === null) {
      throw new Error("World not set");
    }
    return this.world;
  }
}
