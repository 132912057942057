import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { assetsSlice } from "./assets";
import { companiesSlice } from "./companies";
import { companyOwnershipsSlice } from "./companyOwnerships";
import { incomesSlice } from "./incomes";
import { peopleSlice } from "./people";
import { propertyOwnershipsSlice } from "./propertyOwnerships";

import { loadState, saveState } from "../localStorage";

const persistedState = loadState();

const rootReducer = combineReducers({
  assets: assetsSlice.reducer,
  companies: companiesSlice.reducer,
  companyOwnerships: companyOwnershipsSlice.reducer,
  incomes: incomesSlice.reducer,
  people: peopleSlice.reducer,
  propertyOwnerships: propertyOwnershipsSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
});

store.subscribe(() => {
  saveState(store.getState());
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
