import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ModalContext = React.createContext({
  close: () => {
    /*  */
  },
});

export interface ModalProps {
  children: React.ReactNode;
  className?: string;
  initialFocus?: React.MutableRefObject<HTMLElement | null>;
}

function Modal(props: ModalProps) {
  const { children, className } = props;

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const context = {
    close: () => {
      setIsOpen(false);
    },
  };

  return (
    <Transition.Root
      appear
      show={isOpen}
      as={Fragment}
      afterLeave={() => navigate(-1)}
    >
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
        initialFocus={props.initialFocus}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 dark:bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`${
                  className ?? ""
                } relative sm:my-8 w-full transform text-left align-middle shadow-xl transition-all`}
              >
                <ModalContext.Provider value={context}>
                  {children}
                </ModalContext.Provider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.Title = Dialog.Title;
Modal.Description = Dialog.Description;

interface HeaderProps {
  title: string;
  close: () => void;
}

Modal.Header = function (props: HeaderProps) {
  const { title, close } = props;

  return (
    <div className="px-4 py-4 bg-gray-50 dark:bg-gray-900 sm:p-6 sm:py-5 rounded-t-2xl flex items-center">
      <Modal.Title
        as="h3"
        className="text-lg font-medium leading-6 text-gray-900 dark:text-white mr-auto"
      >
        {" "}
        {title}
      </Modal.Title>

      <button
        type="button"
        className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={() => close()}
        tabIndex={-1}
      >
        <span className="sr-only">Close</span>
        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  );
};

Modal.Body = function ({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-white dark:bg-slate-800 px-4 pb-4 sm:px-6 pt-5">
      {children}
    </div>
  );
};

Modal.Footer = function ({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-gray-50 dark:bg-gray-900 rounded-b-2xl px-4 sm:px-6 py-3 sm:flex sm:flex-row-reverse gap-3">
      {children}
    </div>
  );
};

export default Modal;
