import { useTranslation } from "react-i18next";

import Select, { Option, SelectProps } from "./Select";

interface MonthProps
  extends Omit<SelectProps<Option>, "options" | "onChange" | "value"> {
  onChange: (month: number | null) => void;
  value: number | null;
}

export default function MonthSelect(props: MonthProps) {
  const { t } = useTranslation();

  const monthNames = t("months", { returnObjects: true });
  const monthOptions = monthNames.map((name, idx) => ({
    id: idx.toString(),
    name: name,
  }));

  const { onChange, value, ...rest } = props;

  return (
    <Select
      onChange={(value) => {
        onChange(value ? parseInt(value) : null);
      }}
      options={monthOptions}
      value={value ? value.toString() : null}
      {...rest}
    />
  );
}
