import IGrowthStrategy from "./IGrowthStrategy";

export default class FixedGrowthStrategy implements IGrowthStrategy {
  readonly growth: number;

  constructor(growth: number) {
    this.growth = growth;
  }

  change(amount: number): number {
    return amount + this.growth;
  }
}
