import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../components/atoms/Button";
import Modal, { ModalContext } from "../../components/atoms/Modal";
import InputField from "../../components/fields/Field";
import Select from "../../components/fields/Select";
import GenerateId from "../../IDGen";
import { RootState } from "../../store";
import { added } from "../../store/companyOwnerships";
import { Person } from "../../store/people";

interface FormValues {
  personId: string | null;
  shares: number | null;
}

export default function OwnershipForm() {
  const { companyId, ownershipId } = useParams();
  if (!companyId) {
    return null;
  }

  const ownership = useSelector((state: RootState) => {
    if (!ownershipId) {
      return null;
    }
    return state.companyOwnerships[ownershipId];
  });

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      personId: ownership ? ownership.personId : undefined,
      shares: ownership?.shares ?? null,
    },
  });

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (data: FormValues, atEnd: () => void) => {
      const { personId, shares } = data;

      if (shares) {
        dispatch(
          added({
            id: ownershipId ? ownershipId : GenerateId("ownr"),
            companyId,
            personId: personId ?? null,
            shares,
          })
        );
      }

      atEnd();
    },
    [companyId, ownershipId]
  );

  const { t } = useTranslation("companies");
  const common = useTranslation("common").t;

  const allPeople = useSelector((state: RootState) => state.people);

  const peopleOptions = [
    ...Object.values(allPeople).map((p: Person) => ({
      id: p.id,
      name: p.name,
    })),
    {
      id: null,
      name: "Another person",
    },
  ];

  return (
    <Modal className="sm:max-w-lg">
      <ModalContext.Consumer>
        {({ close }) => (
          <>
            <Modal.Header title={t("ownershipForm.title")} close={close} />

            <form onSubmit={handleSubmit((data) => onSubmit(data, close))}>
              <Modal.Body>
                <div className="mb-4">
                  <Controller
                    render={({ field }) => (
                      <Select
                        id="company-ownership-personId"
                        label={t("ownershipForm.person")}
                        description={t("ownershipForm.personDescription")}
                        placeholder={t("ownershipForm.personPlaceholder")}
                        error={errors.personId}
                        options={peopleOptions}
                        {...field}
                      />
                    )}
                    control={control}
                    name="personId"
                  />
                </div>
                <div className="mb-3">
                  <InputField
                    id="company-ownership-shares"
                    label={t("ownershipForm.shares")}
                    description={t("ownershipForm.sharesDescription")}
                    placeholder={t("ownershipForm.sharesPlaceholder")}
                    error={errors.shares}
                    min={1}
                    {...register("shares", {
                      required: t("ownershipForm.sharesRequired"),
                      min: {
                        value: 1,
                        message: t("ownershipForm.sharesMin"),
                      },
                      valueAsNumber: true,
                    })}
                  />
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="primary" type="submit">
                  {common("confirm")}
                </Button>

                <Button variant="white" onClick={close}>
                  {common("back")}
                </Button>
              </Modal.Footer>
            </form>
          </>
        )}
      </ModalContext.Consumer>
    </Modal>
  );
}
