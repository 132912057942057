import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Button from "../../components/atoms/Button";
import Modal, { ModalContext } from "../../components/atoms/Modal";

import CountrySelect from "../../components/fields/CountrySelect";
import InputField from "../../components/fields/Field";

import { CountryIso } from "../../Countries";
import GenerateId from "../../IDGen";
import { added } from "../../store/companies";

interface FormValues {
  name: string;
  countryCode?: CountryIso;
}

export default function CompaniesNew() {
  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      countryCode: undefined,
    },
  });

  const dispatch = useDispatch();

  const onSubmit = useCallback((data: FormValues, atEnd: () => void) => {
    const { name, countryCode } = data;

    if (countryCode) {
      dispatch(
        added({
          id: GenerateId("corp"),
          name,
          countryCode,
          ownershipIds: [],
        })
      );
    }

    atEnd();
  }, []);

  const { t } = useTranslation("companies");
  const common = useTranslation("common").t;

  const nameForm = register("name", {
    required: t("newForm.nameError"),
  });

  return (
    <Modal className="sm:max-w-lg">
      <ModalContext.Consumer>
        {({ close }) => (
          <>
            <Modal.Header title={t("newForm.title")} close={close} />

            <form onSubmit={handleSubmit((data) => onSubmit(data, close))}>
              <Modal.Body>
                <div className="mb-4">
                  <InputField
                    id="company-name"
                    label={t("newForm.name")}
                    error={errors.name}
                    placeholder={t("newForm.namePlaceholder")}
                    description={t("newForm.nameDescription")}
                    autoComplete="off"
                    {...nameForm}
                  />
                </div>
                <div className="mb-3">
                  <Controller
                    name="countryCode"
                    control={control}
                    rules={{
                      required: t("newForm.countryCodeError"),
                    }}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <CountrySelect
                        id="company-country"
                        label={t("newForm.countryCode")}
                        placeholder={t("newForm.countryCodePlaceholder")}
                        description={t("newForm.countryCodeDescription")}
                        onChange={onChange}
                        iso={value}
                        name={name}
                        error={errors.countryCode}
                        inputRef={ref}
                      />
                    )}
                  />
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="primary" type="submit">
                  {common("confirm")}
                </Button>

                <Button variant="white" onClick={close}>
                  {common("back")}
                </Button>
              </Modal.Footer>
            </form>
          </>
        )}
      </ModalContext.Consumer>
    </Modal>
  );
}
