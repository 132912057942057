import Hashids from "hashids";

const ID_SALT = "correct horse battery staple";

export default function GenerateId(prefix: string) {
  const hashids = new Hashids(ID_SALT);
  const id = Array(2)
    .fill(Math.pow(2, 32))
    .map((max) => Math.floor(Math.random() * max));

  return prefix + "_" + hashids.encode(id);
}
