import { EyeIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { RootState } from "../../store";

export default function PeopleList() {
  const people = useSelector((state: RootState) => state.people);

  return (
    <div>
      <h1 className="text-5xl mb-12">People page</h1>
      <p className="mb-3">
        Your journey to financial freedom starts with you, but it doesn't have
        to be a lonely one. You can add your partner here so you can track your
        progress together. And why not, maybe even a good friend or two.
      </p>

      <Link to="/people/new">Add a person</Link>

      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
      >
        {Object.values(people).map((person) => (
          <li
            key={person.name}
            className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white text-black shadow"
          >
            <Link to={`/people/${person.id}`}>
              <div className="grid grid-cols-1 divide-y">
                <div className="py-4 text-lg font-medium text-center w-full">
                  🧑 {person.name}
                </div>
                <div className="py-4 inline-flex items-center justify-center text-gray-600">
                  <EyeIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">Open</span>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>

      <Outlet />
    </div>
  );
}
