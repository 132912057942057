import IInflationProvider from "./IInflationProvider";
import IClock from "../Clock/IClock";

export default class StaticInflation implements IInflationProvider {
  constructor(private readonly inflation: number = 0.02) {}

  getInflation(_clock: IClock): number {
    return this.inflation;
  }
}
