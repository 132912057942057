import { RootState } from "./store";

export function loadState(): RootState | undefined {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState) as RootState;
  } catch (err) {
    return undefined;
  }
}

export function saveState(state: RootState) {
  const serializedState = JSON.stringify(state);
  localStorage.setItem("state", serializedState);
}
