import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button, { ButtonClassNames } from "../../components/atoms/Button";
import { formatCurrency } from "../../Currencies";
import { RootState } from "../../store";
import { Income, removed } from "../../store/incomes";
import { Person } from "../../store/people";

export interface IncomesProps {
  person: Person;
}

export default function Incomes(props: IncomesProps) {
  const { person } = props;

  const { t } = useTranslation("incomes");

  const dispatch = useDispatch();
  const remove = (income: Income) => {
    dispatch(removed(income));
  };

  const incomes = useSelector((state: RootState) => {
    return Object.values(state.incomes).filter(
      (income) => income.personId === person.id
    );
  });

  return (
    <div>
      <h1>Incomes</h1>

      <div className="text-sm mb-3 bg-teal-100 p-3 rounded-md">
        {t("list.title")}
      </div>

      <table className="table-auto w-full">
        <tbody>
          {incomes.map((income) => {
            return (
              <tr key={income.id}>
                <td className="p-3 pl-0">
                  <div>{person.name}</div>
                  <div className="text-sm text-gray-500">
                    {formatCurrency(income.initialAmount)}
                  </div>
                </td>
                <td className="p-3 pr-0 text-right">
                  <Link
                    to={`/people/${person.id}/incomes/${income.id}`}
                    className={ButtonClassNames({
                      size: "small",
                      variant: "secondary",
                    })}
                  >
                    Edit
                  </Link>
                  <Button
                    variant="outlineDanger"
                    size="small"
                    className="ml-2"
                    onClick={() => remove(income)}
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Link to="incomes/new">Add income</Link>
    </div>
  );
}
