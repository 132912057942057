import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Frequency, GrowthStrategy, Money, TimestampRange } from "./types";

export type Income = {
  id: string;
  name: string;
  schedule: TimestampRange;

  personId?: string;
  companyId?: string;

  initialAmount: Money;
  frequency: Frequency;
  growthStrategy: GrowthStrategy;
} & (
  | {
      type: "salary";
      personId: string;
      taxedAtSource: boolean;
      employeePensionContributionRate: number;
      employerPensionContributionRate: number;
    }
  | {
      type: "generic";
    }
);

export type IncomesState = Record<string, Income>;

const initialState: IncomesState = {};

export const incomesSlice = createSlice({
  name: "incomes",
  initialState,
  reducers: {
    added: (state, action: PayloadAction<Income>) => {
      state[action.payload.id] = action.payload;
    },

    updated: (state, action: PayloadAction<Income>) => {
      state[action.payload.id] = action.payload;
    },

    removed: (state, action: PayloadAction<Income>) => {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete state[action.payload.id];
    },
  },
});

export const { added, updated, removed } = incomesSlice.actions;
export default incomesSlice.reducer;
