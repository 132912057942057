import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";

import Incomes from "./Incomes";
import { RootState } from "../../store";

export default function PeopleShow() {
  const personId = useParams().personId;
  if (!personId) {
    return null;
  }

  const person = useSelector((state: RootState) => state.people[personId]);
  // const { t } = useTranslation("companies");

  return (
    <>
      <h1 className="text-3xl font-medium leading-6 mr-auto">{person.name}</h1>

      <Incomes person={person} />
      <Outlet />
    </>
  );
}
