import React, { FocusEvent, useState } from "react";

import Field, { InputProps } from "./Field";
import Input from "./Input";

import {
  CurrencyIso,
  decimalPlacesForCurrency,
  formatCurrency,
  roundMoney,
} from "../../Currencies";
import { parseLocalizedFloat } from "../../i18n";

export interface CurrencyFieldProps extends InputProps {
  currency: CurrencyIso;
  value?: number | string;
  language?: string;
}

const format = (
  value: number | string | undefined,
  currency: CurrencyIso,
  language?: string
): { formatted: string; cleaned: string; numeric?: number } => {
  let parsed: number | undefined;
  if (typeof value === "string") {
    // Accept only digits, signs and separators
    value = value.replace(/[^0-9.,]/g, "");

    // Normalize the use of commas and periods and cast to a number
    parsed = parseLocalizedFloat(value, language ?? navigator.language);
  } else {
    parsed = value;
  }

  // Handle edge cases
  if (parsed === undefined || isNaN(parsed)) {
    return { formatted: "", cleaned: "", numeric: undefined };
  }

  const money = roundMoney({ amount: parsed, currency });

  return {
    formatted: formatCurrency(money, language ?? navigator.language),
    cleaned: value?.toString() ?? "",
    numeric: money.amount,
  };
};

const CurrencyField = React.forwardRef(
  (props: CurrencyFieldProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { onChange, onBlur, value, currency, error, language, ...rest } =
      props;

    const hiddenRef = React.useRef<HTMLInputElement>(null);

    const [asString, setAsString] = useState<string>(
      format(value, currency, language).formatted
    );

    // Transform the value to a nicely formatted currency string
    const blur = (e: React.FocusEvent<HTMLInputElement>) => {
      const { formatted, numeric } = format(e.target.value, currency, language);

      if (hiddenRef.current) {
        hiddenRef.current.value = numeric?.toString() ?? "";
        e.target = hiddenRef.current;
      }

      onChange?.(e);
      onBlur?.(e);
      setAsString(formatted);
    };

    // Transform the float value to a localised, human readable string
    const focus = (e: FocusEvent<HTMLInputElement>) => {
      if (value) {
        setAsString(
          Intl.NumberFormat(language ?? navigator.language, {
            minimumFractionDigits: decimalPlacesForCurrency(currency),
            // @ts-expect-error This is supported from ES2020 onwards, but most browsers already support it anyway
            numberingSystem: "latn",
          }).format(value as number)
        );
      } else if (hiddenRef.current) {
        setAsString(hiddenRef.current.value);
      }

      e.target.select();
    };

    // On every change, only accept valid numbers and update the form value to a float
    const change = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { cleaned, numeric } = format(e.target.value, currency, language);
      setAsString(cleaned);
      if (hiddenRef.current) {
        hiddenRef.current.value = numeric?.toString() ?? "";
        e.target = hiddenRef.current;
      }

      onChange?.(e);
    };

    return (
      <>
        <Input type="hidden" ref={hiddenRef} />
        <Field
          onChange={change}
          onBlur={blur}
          onFocus={focus}
          value={asString}
          error={error}
          ref={ref}
          inputMode="decimal"
          {...rest}
        />
      </>
    );
  }
);

export default CurrencyField;
