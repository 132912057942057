import IncomeFrequency from "./IncomeFrequency";

import { Income as IncomeState } from "../../store/incomes";

import IGrowthStrategy from "../GrowthStrategies/IGrowthStrategy";
import NullGrowthStrategy from "../GrowthStrategies/NullGrowthStrategy";
import IIncome from "../IIncome";
import TimestampRange from "../Timestamps/TimestampRange";

export default class GenericIncome extends IIncome {
  schedule: TimestampRange = TimestampRange.always();

  /**
   * Amount for a "frequency" period
   */
  currentAmount: number;

  /**
   * This is not how frequently the income is paid.
   */
  frequency: IncomeFrequency = IncomeFrequency.Yearly;

  /**
   * We update the income amount every 12 working months.
   * The strategy determines how the income amount changes over time.
   */
  growthStrategy: IGrowthStrategy = new NullGrowthStrategy();

  private ticks = 0;

  constructor(
    public readonly name: string,
    public readonly initialAmount: number,
    public readonly receiverId: string
  ) {
    super();

    this.currentAmount = initialAmount;
  }

  static deserialize(data: IncomeState): GenericIncome {
    if (!data.personId) {
      throw new Error("Person ID not set");
    }

    return new GenericIncome("", data.initialAmount.amount, data.personId);
  }

  monthTick() {
    if (!this.schedule.isCurrent(this.getWorld().clock)) {
      return;
    }

    this.ticks += 1;
    if (this.ticks % 12 === 0) {
      this.grow();
    }

    if (
      this.frequency === IncomeFrequency.Hourly ||
      this.frequency === IncomeFrequency.Daily ||
      this.frequency === IncomeFrequency.Weekly ||
      this.frequency === IncomeFrequency.Monthly
    ) {
      this.pay();
    } else if (
      (this.frequency === IncomeFrequency.Quarterly && this.ticks % 3 === 0) ||
      (this.frequency === IncomeFrequency.TwiceYearly &&
        this.ticks % 6 === 0) ||
      (this.frequency === IncomeFrequency.Yearly && this.ticks % 12 === 0)
    ) {
      this.pay();
    }
  }

  private grow() {
    this.currentAmount = this.growthStrategy.change(this.currentAmount);
  }

  private pay() {
    const person = this.getWorld().people.find((p) => p.id === this.receiverId);
    if (person) {
      person.addIncome(this.effectiveAmount);
    }
  }

  private get effectiveAmount(): number {
    switch (this.frequency) {
      case IncomeFrequency.Hourly:
        return this.currentAmount * 24 * 30;
      case IncomeFrequency.Daily:
        return this.currentAmount * 30;
      case IncomeFrequency.Weekly:
        return this.currentAmount * 4.33;
      default:
        return this.currentAmount;
    }
  }
}
